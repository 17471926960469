import { Item } from "../ui/Item";
import { Separator } from "../ui/Separator";
import { Tree } from "../ui/Tree";


export const elements:any =  {
    navdropdown: Tree,
    navitem: Item,
    navseparator: Separator,

}

