import React from 'react'
import './loader.scss'
type Props = {}

export const Loader = (props: Props) => {
  return (
    <div className="Box-loader">
   <span className="loader"></span>
  </div>
  )
}

