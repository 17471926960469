
export const PdfReaderPage = () => {


  return (
    <div className="Page">
      <div className="Page__body shadow__style">
        <div className="Page__content">
         
        </div>
      </div>
    </div>
  );
};
